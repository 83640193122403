.activity-chatting-command-box {
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: #eee;
    padding: 5px 10px;
    cursor: pointer;
    color: #777;
}

.system-message-comment {
    font-size: 14px;
    color: #428bca;
    cursor: pointer;
}

.system-message-comment-container {
    padding: 20px;
    margin-top: 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.13);
    border-left: 3px solid #428BCA;
    background: #fcfbbb;
    border-radius: 3px;
    position: relative;
}

.system-message-comment-arrow {
    position: absolute;
    font-size: 30px;
    top: 10px;
    left: -20px;
    color: #428BCA;
}

.system-message-comment-container-project-desc {
    margin-top: 10px;
    margin-bottom: 0px;
    border-top: 1px solid #ccc;
    padding-top: 5px;
    font-size: 14px;
    color: #777;
    font-weight: 600;
}

    .system-message-comment-container-project-desc b {
        color: #428BCA;
    }

.system-message-comment-container-remark {
    margin-bottom: 0px;
    padding: 10px;
    padding-left: 60px;
    position: relative;
}

    .system-message-comment-container-remark.mention-focus {
        background: rgba(255, 169, 0, 0.26) !important;
    }

.system-message-comment-container .system-message-comment-container-reply {
    margin-top: 10px;
    position: relative;
}

.system-message-comment-container-reply .reply-attach {
    margin-right: 20px;
    font-size: 26px;
    color: #999;
    cursor: pointer;
}

    .system-message-comment-container-reply .reply-attach:hover {
        color: #777;
    }

.system-message-comment-container-reply-file-container {
    margin: 10px 0;
    padding: 15px 10px;
    background: #eee;
    border: 1px solid #ccc;
    position: relative;
}





.system-message-comment-container .system-message-comment-container-reply textarea {
    resize: none;
}

.system-message-comment-container-remark-more {
    color: #3B5998;
    margin-bottom: 20px;
    cursor: pointer;
    padding-left: 10px;
}

.system-message-comment-container-remark-date {
    color: #aaa;
    float: right;
}

.system-message-comment-container-remark-fullname {
    color: #428BCA !important;
}

.system-message-comment-container-remark-message {
    margin-top: 10px;
    min-height: 18px;
}

.system-message-comment-container-remark-img {
    color: #428BCA;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.system-message-comment-container .system-message-comment-container-remark div.system-message-comment-container-remark-img {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.system-message-comment-container .easy-mode .system-message-comment-container-remark .system-message-comment-container-remark-img {
    width: 35px;
    height: 35px;
    top: 5px;
    left: 13px;
}

.system-message-comment-container .system-message-comment-container-remark-owner {
    background: #F3FCBB;
}

    .system-message-comment-container .system-message-comment-container-remark-owner .system-message-comment-container-remark-fullname {
        color: #AED55C;
    }


.system-message-comment-container .system-message-quote {
    margin: 10px auto;
    padding: 10px 20px;
    background: #eee;
    border-left: 3px solid #428bca;
}

.system-message-attach-image-block,
.system-message-attach-file-block {
    width: 120px;
    height: 120px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    background: #fff;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 4px;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    font-size: 12px;
    padding: 13px 8px;
    text-align: center;
    overflow: hidden;
    line-height: 18px;
    white-space: normal;
    word-break: break-word;
}

.system-message-attach-image-block-adder {
    width: 120px;
    height: 120px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 2px dashed #CCB;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    font-size: 30px;
    color: #aaa;
    padding-top: 40px;
}

.fs-26 {
    font-size: 24px;
}
/*.system-message-attach-file-block{
    display:inline-block;
    margin-right:5px;
    margin-bottom:5px;
    background:#fff;
    width:200px;
    box-shadow:none;
    border-radius:5px;
    border:1px solid #ccc;
    overflow-x:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    padding:8px 10px;
}*/

.system-message-attach-files {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

    .system-message-attach-files .file-remover,
    .file-insert-remover {
        position: absolute;
        top: -3px;
        right: -3px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.7);
        border: 2px solid #fff;
        color: #fff;
        z-index: 10;
        text-align: center;
        padding-top: 1px;
        cursor: pointer;
        display: none;
    }

/*============ Highlight ===========================*/

.remark-highlight-command-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1900;
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    background: none;
    background-color: transparent;
}

.remark-highlight-command-shortcut {
    position: absolute;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.42);
    border-radius: 5px;
    background: #fff;
    z-index: 2000;
    cursor: pointer;
    color: #428bca;
    font-size: 20px;
}

.remark-highlight-command-container .remark-highlight-command-box {
    z-index: 2000;
    position: absolute;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.42);
    border-radius: 5px;
    width: 700px;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    padding-bottom: 20px;
    max-height: 90vh;
    overflow: auto;
}

    /*.remark-highlight-command-container .remark-highlight-command-box::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #777;
}

.remark-highlight-command-container .remark-highlight-command-box::-webkit-scrollbar
{
	width: 6px;
	background-color: #f6f6f6;
}

.remark-highlight-command-container .remark-highlight-command-box::-webkit-scrollbar-thumb
{
	background-color: #ccc;
    border-radius: 5px;
}*/

    .remark-highlight-command-container .remark-highlight-command-box .fa {
        cursor: pointer;
        color: #777;
    }

    .remark-highlight-command-container .remark-highlight-command-box .remark-highlight-command-box-quote {
        max-height: 250px;
        overflow-y: auto;
        padding: 10px 20px;
        background: #FCFBBB;
        border-left: 5px solid #428bca;
    }

/*================================ Highlight Tab ============================== */
.remark-highlight-command-container .highlight-tab-panel-container {
    margin-top: 15px;
}

    .remark-highlight-command-container .highlight-tab-panel-container .highlight-tab-panel-header span {
        padding: 8px 15px;
        border: 1px solid #ccc;
        border-radius: 5px 5px 0 0;
        background: #eee;
        cursor: pointer;
        border-bottom: 0px;
    }

        .remark-highlight-command-container .highlight-tab-panel-container .highlight-tab-panel-header span.is-active {
            font-weight: 600;
            background: #fff;
        }

    .remark-highlight-command-container .highlight-tab-panel-container .highlight-tab-panel-content {
        margin-top: 5px;
    }

        .remark-highlight-command-container .highlight-tab-panel-container .highlight-tab-panel-content .highlight-tab-panel-content-element {
            display: none;
            border: 1px solid #ccc;
            border-radius: 0 0 5px;
            padding: 10px;
        }

    .remark-highlight-command-container .highlight-tab-panel-container .create-activity-frame {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        display: none;
        z-index: 20000;
    }

    .remark-highlight-command-container .highlight-tab-panel-container textarea.form-control {
        height: auto !important;
        resize: vertical !important;
    }

.system-message-comment-container-reply .mentions {
    padding: 9px;
    font-size: 14px;
}


/*================================== Mail View ============================== */

.system-message-mail-view {
    /*background:#fff;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius:3px;*/
}

    .system-message-mail-view .mail-table {
        width: 100%;
    }

        .system-message-mail-view .mail-table td {
            vertical-align: top;
            padding-bottom: 5px;
        }

    .system-message-mail-view .mail-top {
        /*background: #f7f7f7;
    padding:10px;
    border-bottom: 1px solid #f7f7f7;*/
    }

    .system-message-mail-view .mail-line-subject {
        width: 80px;
        font-weight: 600;
    }

    .system-message-mail-view .mail-line-detail {
        padding: 10px;
    }

.system-message-mail-arrow {
    position: absolute;
    top: 60px;
    font-size: 18px;
    left: 23px;
}

    .system-message-mail-arrow.arrow-in {
        color: #009688;
    }

    .system-message-mail-arrow.arrow-out {
        color: #E65041;
    }

.btn-checkin,
.btn-checkin:active,
.btn-checkin:focus {
    background: #EB3F79;
    border-color: #EB3F79;
}

.image-box-reradius {
    border-radius: 5px;
}

.image-box {
    border-radius: 5px;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
}

.feed-container {
    background: #fff;
    margin-bottom: 10px;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 3px;
}

.feed-activity-header {
    padding-left: 50px;
    position: relative;
}

.feed-container .feed-activity-header .img-circle-box {
    border: 1px solid #ccc;
    background-size: 100% auto;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 0 auto;
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
}


.feed-activity-event {
    margin-bottom: 5px;
    /*overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;*/
    padding-right: 20px;
}

.feed-activity-name {
    color: #3B5998;
    font-weight: 600;
    cursor: pointer;
}

.feed-activity-datetime {
    color: #aaa;
}

.feed-activity-message {
    margin-top: 15px;
    padding: 10px;
    padding-left: 10px;
    border-left: 3px solid #92a7b9;
}

.feed-activity-remark {
    background: #F6F7F8;
    border-top: 1px solid #eee;
}

.feed-activity-command {
    padding: 10px 5px;
    border-top: 1px solid #eee;
    margin: 0 10px;
    color: #7f7f7f;
}

.feed-activity-remark .system-message-comment-container {
    background: none;
    border: none;
    box-shadow: none;
    padding: 10px;
    display: none;
    margin-top: 0px;
}

    .feed-activity-remark .system-message-comment-container .system-message-comment-container-reply {
        padding-left: 60px;
        padding-right: 10px;
    }

    .feed-activity-remark .system-message-comment-container .system-message-comment-container-remark {
        padding-top: 0;
        padding-bottom: 0;
    }

    .feed-activity-remark .system-message-comment-container .system-message-comment-container-remark-owner {
        background: none;
    }

    .feed-activity-remark .system-message-comment-container .system-message-comment-container-remark-img {
        width: 35px;
        height: 35px;
        left: 15px;
        top: 0;
    }

#feed-create .btn-group .btn {
    width: auto;
    box-shadow: none;
    font-weight: 600;
}

.visible-onwide {
    display: none;
}

@media(min-width:1024px) {
    .visible-onwide {
        display: initial;
    }
}

.panel-feed-remark-content {
    margin-top: 15px;
}

    .panel-feed-remark-content .panel-feed-remark-content-image {
        height: 200px;
        width: 50%;
        cursor: pointer;
        display: inline-block;
        background-position: center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -o-background-size: cover;
        background-color: #FCFCFC;
        border: 1px solid #ddd;
        position: relative;
    }

    .panel-feed-remark-content .main-child {
        height: 360px;
        width: 100%;
    }

.feed-more-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: rgba(221, 221, 221, 0.5);
    font-size: 3em;
    text-align: center;
    padding-top: 70px;
}

    .feed-more-image:hover {
        color: rgba(221, 221, 221, 0.9);
    }

.system-message-attach-file-block {
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    cursor: pointer;
    box-shadow: none;
}


.panel-feed-activity-card {
    margin-top: 15px;
    background: #fff;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 3px;
}

.panel-feed-activity-card-top {
    padding: 10px;
    border-bottom: 1px solid #d0d1d5;
    border-color: #e5e6e9 #dfe0e4;
    position: relative;
}

.panel-feed-activity-card-title-pic {
    width: 100%;
    height: 265px;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    background-color: #FCFCFC;
    position: relative;
    background-attachment: scroll;
}

.panel-feed-activity-card-top table {
    width: 100%;
    table-layout: fixed;
}

    .panel-feed-activity-card-top table tr td {
        padding: 10px;
    }

        .panel-feed-activity-card-top table tr td:first-child {
            padding-left: 0px;
            width: 70px;
        }

.panel-feed-activity-card-bottom {
    padding: 15px;
    background: #F6F7F8;
}

.panel-feed-activity-card-footer {
    padding: 15px;
}

.panel-feed-activity-card-footer-inner {
    line-height: 20px 15px;
    max-height: 120px;
    overflow-y: hidden;
}

.panel-feed-activity-card-footer-inner-detail {
    margin-top: 10px;
}

.problem-type {
    background: #AEC9EF;
    padding: 5px 15px;
    text-align: center;
    color: #000;
    display: inline-block;
    min-width: 150px;
}

.feed-activity-event .btn {
    width: auto;
    box-shadow: none;
    margin-right: -20px;
    margin-left: 10px;
    padding: 2px 5px;
    margin-top: -3px;
    float: right;
    min-width: 130px;
}

#feed-new-more {
    color: #333;
    padding: 15px;
    display: none;
}

    #feed-new-more:hover {
        color: #ccc;
    }

.btn-light[disabled] {
    background: #7cc245;
    border-color: #7cc245;
}

.information-banner {
    background-color: #efc439;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    padding: 0 5px;
    vertical-align: baseline;
    float: right;
}

.invitation-banner {
    background-color: #b6c569;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    padding: 0 5px;
    vertical-align: baseline;
    float: right;
}

.invitation {
    padding: 0px;
    height: 350px;
    background-attachment: scroll;
}

.invitation-panel {
    width: 100%;
    height: 100%;
    display: table;
}

    .invitation-panel:hover {
        width: 100%;
        height: 100%;
        display: table;
    }

.invitation-panel-inner {
    vertical-align: middle;
    text-align: center;
    color: #fff;
    display: table-cell;
}

    .invitation-panel-inner .subject {
        font-size: 20px;
    }

.flip-container p > br {
    margin-bottom: 1em !important;
}
/* START: Accommodating for IE */
.flip-container:hover .back, .flip-container.hover .back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.flip-container:hover .front, .flip-container.hover .front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
/* END: Accommodating for IE */
.flip-container, .flip-container .front, .flip-container .back {
    width: 100%;
    height: 100%;
}

.flipper {
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 0.6s;
    -moz-transition: 0.6s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 0.6s;
    transform-style: preserve-3d;
    display: block;
    position: relative;
    height: 100%;
}

.flip-container .front, .flip-container .back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 0.6s;
    -moz-transform-style: preserve-3d;
    -o-transition: 0.6s;
    -o-transform-style: preserve-3d;
    -ms-transition: 0.6s;
    -ms-transform-style: preserve-3d;
    transition: 0.6s;
    transform-style: preserve-3d;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
}

.flip-container .front {
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    display: table;
    background: rgba(0, 0, 0, 0.4);
}

.flip-container .back {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    display: table;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

    .flip-container .back p, .flip-container .front p {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        text-align: center;
        padding: 30px;
    }

        .flip-container .back p span:nth-child(even) {
            color: gold;
        }

        .flip-container .back p span:nth-child(odd) {
            color: #fffac6;
        }

.popover {
    min-width: 50em !important;
}
